@font-face {
  font-family: "Avenir Light";
  src: url("../fonts/avenir-light.eot?#iefix");
  src: url("../fonts/avenir-light.eot?#iefix") format("eot"),
    url("../fonts/avenir-light.woff2") format("woff2"),
    url("../fonts/avenir-light.woff") format("woff"),
    url("../fonts/avenir-light.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Book";
  src: url("../fonts/avenir-book.eot?#iefix");
  src: url("../fonts/avenir-book.eot?#iefix") format("eot"),
    url("../fonts/avenir-book.woff2") format("woff2"),
    url("../fonts/avenir-book.woff") format("woff"),
    url("../fonts/avenir-book.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Book Oblique";
  src: url("../fonts/avenir-book-oblique.eot?#iefix");
  src: url("../fonts/avenir-book-oblique.eot?#iefix") format("eot"),
    url("../fonts/avenir-book-oblique.woff2") format("woff2"),
    url("../fonts/avenir-book-oblique.woff") format("woff"),
    url("../fonts/avenir-book-oblique.ttf") format("truetype");
}

@font-face {
  font-family: "Avenir Black";
  src: url("../fonts/avenir-black.eot?#iefix");
  src: url("../fonts/avenir-black.eot?#iefix") format("eot"),
    url("../fonts/avenir-black.woff2") format("woff2"),
    url("../fonts/avenir-black.woff") format("woff"),
    url("../fonts/avenir-black.ttf") format("truetype");
}

$crimson: #cf0c2c;

$pro-landscape: 1366px;
$breakpoint-xl: 1200px;
$ipad-landscape: 1024px;
$breakpoint-lg: 992px;
$x-landscape: 812px;
$ipad-portrait: 768px;
$plus-landscape: 736px;
$six-landscape: 667px;
$se-landscape: 568px;
$breakpoint-sm: 576px;
$plus-portrait: 414px;
$x-portrait: 375px;
$se-portrait: 320px;

html {
  height: 100%;
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    background: $crimson;
  }
}

header {
  position: relative;
  overflow: hidden;
  height: 100vh;
  background: url("../images/video-placeholder.jpg") no-repeat center center
    $crimson;
  background-size: cover;
  transition: height 1s ease-in-out;
  @media (min-width: $ipad-portrait) {
    height: 56.667vh;
  }
  @media (min-width: $breakpoint-lg) {
    height: 66.667vh;
  }
  @media (min-width: $ipad-landscape) and (min-height: $pro-landscape) {
    height: 33.334vh;
  }
  @media (min-width: $pro-landscape) {
    height: 75vh;
  }
  &.small-header {
    height: 35vh;
    transition: height 1s ease-in-out;
  }
  .message {
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    padding: 32px 16px 100% 16px;
    background: white;
    @media (min-width: $se-landscape) {
      top: 33.337%;
    }
    @media (min-width: $x-portrait) {
      padding: 32px 32px 100% 32px;
    }
    @media (min-width: $ipad-portrait) {
      left: 32px;
      right: auto;
      width: 35%;
      top: 80px;
      padding: 0;
    }
    @media (min-width: $breakpoint-lg) {
      left: 44px;
      top: 98px;
    }
    @media (min-width: $breakpoint-xl) {
      background: white;
      top: 0;
      left: 0;
      height: 100%;
      padding: 64px 7%;
    }
  }
  h1 {
    font-family: "Avenir Black", "Helvetica", sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    font-size: 32px;
    line-height: 32px;
    margin: 0;
    background: white;
    .crimson {
      color: $crimson;
    }
    @media (min-width: $breakpoint-xl) {
      font-size: 52px;
      line-height: 64px;
    }
  }
  p {
    text-transform: uppercase;
    font-family: "Avenir Light", "Helvetica", sans-serif;
    font-weight: 300;
    color: $crimson;
    font-size: 20px;
    line-height: 32px;
    margin: 16px 0 0 0;
    @media (min-width: $breakpoint-xl) {
      font-size: 32px;
      line-height: 48px;
    }
  }
  .logo {
    max-height: 96px;
    margin-bottom: 16px;
  }
  .svg-mask {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 66.667%;
    width: auto;
    left: -98%;
    @media (min-height: 812px) and (min-width: 375px) {
      left: -125%;
    }
    @media (min-width: $plus-portrait) {
      left: -98%;
    }
    @media (min-width: $ipad-portrait) {
      left: 0;
      height: 74.667%;
    }
    @media (min-width: $breakpoint-lg) {
      height: auto;
      width: 100%;
    }
  }
  .gif-mobile-fallback {
    min-width: 100%;
    @media (min-width: $x-portrait) {
      min-height: 52vh;
      min-width: auto;
    }
    @media (min-width: $plus-landscape) {
      min-width: 100%;
      min-height: auto;
    }
  }
  video {
    min-width: 100%;
  }
}
.calculator {
  background: $crimson;
  padding: 0;
  form,
  .results {
    max-width: 740px;
    margin: 0 auto;
    padding: 32px 16px;
    background: white;
    z-index: 1;
    position: relative;
    top: -64px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    @media (min-width: $x-portrait) {
      top: -128px;
      padding: 32px;
    }
    @media (min-width: $se-landscape) {
      top: -48px;
    }
    @media (min-width: $plus-landscape) {
      top: -192px;
    }
    @media (min-width: $ipad-landscape) {
      top: -96px;
    }
    @media (min-width: $ipad-landscape) and (min-height: $ipad-portrait) {
      top: -64px;
    }
    @media (min-width: $breakpoint-xl) {
      top: -32px;
    }
    @media (min-width: $pro-landscape) {
      top: -64px;
    }
    h2 {
      font-family: "Avenir Black", "Helvetica", sans-serif;
      font-weight: 900;
      font-size: 32px;
      line-height: 32px;
      margin: 0 0 16px 0;
      color: $crimson;
    }
    h3 {
      font-family: "Avenir Light", "Helvetica", sans-serif;
      font-weight: 100;
      font-size: 32px;
      line-height: 48px;
      margin: 0 0 32px 0;
      color: black;
      strong {
        font-family: "Avenir Black", "Helvetica", sans-serif;
        font-weight: 900;
        color: $crimson;
      }
    }
    p {
      font-family: "Avenir Book", "Helvetica", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 24px 0;
      &.small {
        font-size: 13px;
        line-height: 16px;
      }
      strong {
        font-family: "Avenir Black", "Helvetica", sans-serif;
        font-weight: 900;
      }
    }
  }
}
.container {
  max-width: 720px;
  margin: 0 auto;
  padding: 0 32px 64px 32px;
}
.progress-indicator {
  padding: 0 16px;
  margin-top: -96px;
  @media (min-width: $x-portrait) {
    margin-top: -256px;
  }
  @media (min-width: $se-landscape) {
    margin-top: -48px;
  }
  @media (min-width: $plus-landscape) {
    margin-top: -192px;
  }
  @media (min-width: $ipad-landscape) {
    margin-top: -32px;
  }
  @media (min-width: $ipad-landscape) and (min-height: $ipad-portrait) {
    margin-top: -64px;
  }
  @media (min-width: $ipad-landscape) and (min-height: $pro-landscape) {
    margin-top: -64px;
  }
  @media (min-width: $pro-landscape) and (min-height: $ipad-landscape) {
    margin-top: -32px;
  }
  @media (min-width: $pro-landscape) {
    margin-top: -64px;
  }
  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 8px 0;
    margin: 0;
    li {
      font-family: "Avenir Book", "Helvetica", sans-serif;
      font-weight: 400;
      color: transparentize(white, 0.33);
      font-size: 16px;
      line-height: 32px;
      strong {
        font-family: "Avenir Black", "Helvetica", sans-serif;
        font-weight: 900;
        color: white;
      }
    }
  }
}
.text-center {
  text-align: center !important;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  &:focus-within {
    label {
      color: $crimson;
    }
  }
  label {
    font-family: "Avenir Black", "Helvetica", sans-serif;
    font-weight: 900;
    font-size: 16px;
    line-height: 32px;
    transition: all 0.33s ease-in-out;
    .hint {
      font-size: 13px;
      line-height: 32px;
      font-family: "Avenir Book", "Helvetica", sans-serif;
      font-weight: 400;
      color: $crimson;
    }
  }
  input[type="text"],
  input[type="email"],
  select {
    appearance: none;
    border: 2px solid black;
    background: darken(white, 4%);
    font-family: "Avenir Book", "Helvetica", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 10px 16px;
    border-radius: 4px;
    transition: all 0.33s ease-in-out;
    &:focus {
      border: 4px solid $crimson;
      padding: 8px 14px;
      outline: none;
    }
  }
  input[type="checkbox"] {
    height: 16px;
    width: 16px;
    margin: 8px;
  }
  &.inline {
    flex-direction: row;
    label {
      font-family: "Avenir Book", "Helvetica", sans-serif;
      font-weight: 400;
    }
  }
  &.input-date {
    display: flex;
    margin-bottom: 0;
    select {
      margin-bottom: 16px;
    }
  }
  .select-group {
    position: relative;
    select {
      width: 100%;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      right: 16px;
      top: 20px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 8px 8px 0 8px;
      border-color: black transparent transparent transparent;
      transition: all 0.33s ease-in-out;
    }
    &:focus-within {
      &:after {
        border-color: $crimson transparent transparent transparent;
      }
    }
  }
}

.error {
  @extend .shake;
  @extend .animated;
  .input-group {
    input[type="text"],
    input[type="email"],
    select {
      border: 2px solid $crimson;
      background: lighten(red, 49%);
      color: $crimson;
    }
  }
}

.alert {
  &.warning {
    color: $crimson;
  }
}

.cta {
  display: flex;
  flex-direction: column;
  margin: 16px 0 0 0;
  padding: 0;
  list-style: none;
  li {
    margin: 0 0 16px 0;
    text-align: center;
    @media (min-width: $breakpoint-lg) {
      margin: 0;
    }
    a {
      display: block;
      @extend button;
      width: auto !important;
      min-width: auto !important;
      text-decoration: none;
      text-align: center !important;
    }
    .btn-secondary {
      border-color: $crimson !important;
      color: $crimson !important;
      &:hover,
      &:focus {
        border-color: darken($crimson, 20%) !important;
        color: white !important;
        background: $crimson !important;
      }
    }

    .btn-primary {
      background: $crimson;
      border: 2px solid crimson;
      color: white !important;
      &:focus,
      &:hover {
        border-color: darken($crimson, 20%) !important;
        background: white;
        color: $crimson !important;
      }
    }

    @media (min-width: $breakpoint-lg) {
      min-width: 160px;
    }
  }
  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
    justify-content: space-around;
    li:first-child {
      order: 1;
    }
    li:nth-child(2) {
      order: 0;
    }
    li:nth-child(3) {
      order: 2;
    }
  }
}

a {
  color: $crimson;
  text-decoration: underline;
  transition: all 0.33s ease-in-out;
  &:hover {
    text-decoration: none;
  }
}

button {
  background: transparent;
  font-family: "Avenir Black", "Helvetica", sans-serif;
  font-weight: 900;
  font-size: 13px;
  line-height: 48px;
  border: 2px solid white;
  border-radius: 4px;
  padding: 0 16px;
  color: white;
  transition: all 0.33s ease-in-out;
  min-width: 100%;
  &:focus,
  &:hover {
    background: white;
    color: $crimson;
    outline: none;
  }
  &[type="submit"] {
    background: $crimson;
    font-family: "Avenir Black", "Helvetica", sans-serif;
    font-weight: 900;
    font-size: 13px;
    line-height: 48px;
    text-transform: uppercase;
    padding: 0 16px;
    color: white;
    border: 2px solid transparent;
    border-radius: 4px;
    &:focus,
    &:hover {
      line-height: 44px;
      padding: 0 14px;
      border: 4px solid darken($crimson, 20%);
      outline: none;
    }
  }
}

.m-0 {
  margin: 0 !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.hidden {
  visibility: hidden !important;
  display: none !important;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  &.delay {
    animation-delay: 1s;
  }
  &.slow {
    animation-duration: 2s;
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

.slideInUp {
  animation-name: slideInUp;
}

.fadeIn {
  animation-name: fadeIn;
}

.shake {
  animation-name: shake;
}

.btn-primary {
  background: $crimson;
  font-family: "Avenir Black", "Helvetica", sans-serif;
  font-weight: 900;
  font-size: 13px;
  line-height: 48px;
  border: 4px solid crimson;
  border-radius: 4px;
  padding: 0 16px;
  transition: all 0.33s ease-in-out;
  display: block;
  width: auto !important;
  min-width: auto !important;
  color: white !important;
  text-decoration: none;
  text-align: center !important;
  &:focus,
  &:hover {
    border-color: darken($crimson, 20%) !important;
  }
  @media (min-width: $breakpoint-lg) {
    margin-bottom: 16px;
    width: 160px !important;
  }
}

.player-wrapper {
  position: relative;
  margin: auto;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.parental-info {
  button {
    color: $crimson;
  }
}
